import humps from 'humps'
import { defineAction } from '_utils/redux'
import * as serviceServices from '_services/service-order'
import { CLIENT_TYPE_CONVERT } from '_utils/user'
import { parseToNumber } from '_utils/helpers'
import { getHistoryLogs } from '_modules/history-logs/actions'

export const GET_SERVICE_ORDERS = defineAction('GET_SERVICE_ORDERS')
export const GET_SERVICE_ORDERS_PDF = defineAction('GET_SERVICE_ORDERS_PDF')
export const GET_MORE_SERVICE_ORDERS = defineAction('GET_MORE_SERVICE_ORDERS')
export const GET_SERVICE_ORDER = defineAction('GET_SERVICE_ORDER')
export const GET_RESPONSIBLE_BY_AGENCY = defineAction('GET_RESPONSIBLE_BY_AGENCY')
export const UPDATE_SERVICE_ORDER = defineAction('UPDATE_SERVICE_ORDER')
export const UPDATE_DISTANCE_BUDGET = defineAction('UPDATE_DISTANCE_BUDGET')
export const REPROVE_SERVICE_ORDER = defineAction('REPROVE_SERVICE_ORDER')
export const UPDATE_SERVICE_ORDER_DATE_SCHEDULE = defineAction('UPDATE_SERVICE_ORDER_DATE_SCHEDULE')
export const UPDATE_APPOINTMENT_DATE_SCHEDULE = defineAction('UPDATE_APPOINTMENT_DATE_SCHEDULE')
export const DELETE_ATTACHMENT = defineAction('DELETE_ATTACHMENT')
export const CANCEL_SERVICE_ORDER = defineAction('CANCEL_SERVICE_ORDER')
export const CLOSE_SERVICE_ORDER = defineAction('CLOSE_SERVICE_ORDER')
export const REFUSE_SERVICE_ORDER = defineAction('REFUSE_SERVICE_ORDER')
export const CANCEL_SERVICE_ORDER_FINISH = defineAction('CANCEL_SERVICE_ORDER_FINISH')
export const VERIFY_NEW_BUDGET = defineAction('VERIFY_NEW_BUDGET')
export const DUPLICATE_SERVICE_ORDER = defineAction('DUPLICATE_SERVICE_ORDER')
export const CHANGE_UNREAD = 'CHANGE_UNREAD'
export const HANDLE_PAGE = 'HANDLE_PAGE'
export const SET_CURRENT = 'SET_CURRENT'
export const GET_SERVICE_ORDER_APPROVAL_AND_EXECUTION_INFOS = defineAction(
  'GET_SERVICE_ORDER_APPROVAL_AND_EXECUTION_INFOS'
)
export const GET_SERVICE_APPROVED = defineAction('GET_SERVICE_APPROVED')
export const GET_TRADESMAN_BY_ID = defineAction('GET_TRADESMAN_BY_ID')
export const GET_REASONS_REPROVE = defineAction('GET_REASONS_REPROVE')
export const GET_CANCEL_SERVICE_APPROVED = defineAction('GET_CANCEL_SERVICE_APPROVED')
export const GET_CANCEL_SERVICE_REJECTED = defineAction('GET_CANCEL_SERVICE_REJECTED')
export const SEND_EMAIL_DEFINITION = defineAction('SEND_EMAIL_DEFINITION')
export const RESEND_EMAIL_ON_CANCEL_SERVICE_REJECTED = defineAction(
  'RESEND_EMAIL_ON_CANCEL_SERVICE_REJECTED'
)

export const GET_CATEGORY_LIST = defineAction('GET_CATEGORY_LIST')
export const GET_CATEGORY_BY_ID = defineAction('GET_CATEGORY_BY_ID')
export const DELETE_CATEGORY = defineAction('DELETE_CATEGORY')
export const UPDATE_CATEGORY = defineAction('UPDATE_CATEGORY')
export const POST_CATEGORY = defineAction('POST_CATEGORY')

export const GET_SERVICE_LIST = defineAction('GET_SERVICE_LIST')
export const GET_SERVICE_BY_ID = defineAction('GET_SERVICE_BY_ID')
export const DELETE_SERVICE = defineAction('DELETE_SERVICE')
export const UPDATE_SERVICE = defineAction('UPDATE_SERVICE')
export const POST_SERVICE = defineAction('POST_SERVICE')

export const GET_PROBLEM_LIST = defineAction('GET_PROBLEM_LIST')
export const GET_PROBLEM_BY_ID = defineAction('GET_PROBLEM_BY_ID')
export const DELETE_PROBLEM = defineAction('DELETE_PROBLEM')
export const UPDATE_PROBLEM = defineAction('UPDATE_PROBLEM')
export const POST_PROBLEM = defineAction('POST_PROBLEM')

export const CREATE_REASON_TO_REPROVE = defineAction('CREATE_REASON_TO_REPROVE')
export const GET_COMMENT = defineAction('GET_COMMENT')
export const UPDATE_COMMENT = defineAction('UPDATE_COMMENT')
export const CREATE_COMMENT = defineAction('CREATE_COMMENT')
export const GET_SERVICE_ORDER_STATUS = defineAction('GET_SERVICE_ORDER_STATUS')
export const GET_PAYMENT_ANTICIPATION_INFO = defineAction('GET_PAYMENT_ANTICIPATION_INFO')
export const GET_PAYMENT_ANTICIPATIONS = defineAction('GET_PAYMENT_ANTICIPATIONS')
export const CREATE_PAYMENT_ANTICIPATION = defineAction('CREATE_PAYMENT_ANTICIPATION')
export const REOPEN_SERVICE_ORDER = defineAction('REOPEN_SERVICE_ORDER')
export const REACTIVATE_SERVICE_ORDER = defineAction('REACTIVATE_SERVICE_ORDER')
export const SEND_EXECUTION_PROOFS_ATTACHMENTS = defineAction('SEND_EXECUTION_PROOFS_ATTACHMENTS')
export const DELETE_EXECUTION_PROOFS_ATTACHMENTS = defineAction(
  'DELETE_EXECUTION_PROOFS_ATTACHMENTS'
)
export const SEND_INVOICE = defineAction('SEND_INVOICE')
export const DELETE_INVOICE = defineAction('DELETE_INVOICE')
export const UPDATE_STEP_STATUS = defineAction('UPDATE_STEP_STATUS')
export const REGISTER_EXECUTION = defineAction('REGISTER_EXECUTION')
export const SCHEDULE_EXECUTION = defineAction('SCHEDULE_EXECUTION')
export const GET_SERVICE_ORDER_TRADESMAN = defineAction('GET_SERVICE_ORDER_TRADESMAN')
export const GET_REASONS_TO_REFUSE_METABASE_SUGGESTION = defineAction(
  'GET_REASONS_TO_DONT_USE_METABASE'
)
export const SET_DEFAULT_SERVICE_ORDER_FILTER = 'SET_DEFAULT_SERVICE_ORDER_FILTER'
export const UPDATE_SERVICE_ORDER_FILTER = 'UPDATE_SERVICE_ORDER_FILTER'
export const GET_STEP_STATUS_FILTER = defineAction('GET_STEP_STATUS_FILTER')
export const GET_ALL_STEP_STATUS_OPTIONS = defineAction('GET_ALL_STEP_STATUS_OPTIONS')
export const GET_ALL_STEP_STATUS_FILTER_OPTIONS = defineAction('GET_ALL_STEP_STATUS_FILTER_OPTIONS')
export const GET_BUTTONS_ACTION = defineAction('GET_BUTTONS_ACTION')
export const GET_GENERIC_PARAMETERS = defineAction('GET_GENERIC_PARAMETERS')
export const GET_GENERIC_PARAMETERS_NEW_FORM = defineAction('GET_GENERIC_PARAMETERS_NEW_FORM')
export const GET_REQUESTER_GENERIC_PARAMETERS = defineAction('GET_REQUESTER_GENERIC_PARAMETERS')
export const COMPLETE_CANCELED_SERVICE_ORDER = defineAction('COMPLETE_CANCELED_SERVICE_ORDER')
export const GET_REACTIVATE_BUTTON = defineAction('GET_REACTIVATE_BUTTON')
export const POST_STATUS_CHANGE = defineAction('POST_STATUS_CHANGE')
export const UPDATE_PAYMENT_ANTICIPATIONS = defineAction('UPDATE_PAYMENT_ANTICIPATIONS')
export const POST_SEND_RATING_LINK = defineAction('POST_SEND_RATING_LINK')
export const POST_SEND_RATING = defineAction('POST_SEND_RATING')
export const POST_CANCEL_EXECUTION_SCHEDULE = defineAction('POST_CANCEL_EXECUTION_SCHEDULE')
export const CANCEL_EXECUTION = defineAction('CANCEL_EXECUTION')
export const GET_ACTIVE_BUDGET = defineAction('GET_ACTIVE_BUDGET')
export const GET_OPTIONS_PAYMENT_RESPONSIBLE = defineAction('GET_OPTIONS_PAYMENT_RESPONSIBLE')
export const VERIFY_WHATSAPP_UPDATE = defineAction('VERIFY_WHATSAPP_UPDATE')
export const UPDATE_REFERA_SERVICE = defineAction('UPDATE_REFERA_SERVICE')

export const UPDATE_SERVICE_ORDER_FLOWS_OPTIONS = defineAction('UPDATE_SERVICE_ORDER_FLOWS_OPTIONS')

export const updatePaymentAnticipations = payload => (dispatch, getState) => {
  const data = serviceServices.updatePaymentAnticipations(getState().user.authToken)(payload)
  dispatch({
    type: UPDATE_PAYMENT_ANTICIPATIONS.ACTION,
    payload: data,
  })
  return data
}

const formatPayload = payload => {
  const newPayload = payload
  if (newPayload.clientType) {
    newPayload.clientType = CLIENT_TYPE_CONVERT[payload.clientType]
  }
  if (newPayload.contactNumber) {
    newPayload.contactNumber = parseToNumber(newPayload.contactNumber)
  }
  if (newPayload.firstContactSecondary) {
    newPayload.firstContactSecondary = parseToNumber(newPayload.firstContactSecondary)
  }
  if (newPayload.secondContactSecondary) {
    newPayload.secondContactSecondary = parseToNumber(newPayload.secondContactSecondary)
  }
  if (newPayload.contactNumber) {
    if (!newPayload?.firstContactSecondary) newPayload.firstContactSecondary = ''
    if (!newPayload?.secondContactSecondary) newPayload.secondContactSecondary = ''
  }
  return newPayload
}

export const getServiceOrders = params => (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().serviceOrders.size,
    order: params?.order || getState().serviceOrders.params?.order,
    orderStatus: params?.orderStatus || getState().serviceOrders.orderStatus,
    ...params,
  }
  dispatch({
    type: GET_SERVICE_ORDERS.ACTION,
    payload: serviceServices.getServiceOrders(getState().user.authToken)(queryParams),
    meta: queryParams,
  })
}

// ** ----------------- CATEGORY ACTIONS
export const getCategoryList = params => (dispatch, getState) => {
  const data = serviceServices.getCategoryList(getState().user.authToken)(
    humps.decamelizeKeys(params)
  )
  dispatch({
    type: GET_CATEGORY_LIST.ACTION,
    payload: data,
    meta: params,
  })
  return data
}

export const getCategoryById = categoryId => (dispatch, getState) => {
  const data = serviceServices.getCategoryById(getState().user.authToken)(categoryId)
  dispatch({
    type: GET_CATEGORY_BY_ID.ACTION,
    payload: data,
  })
  return data
}

export const deleteCategory = categoryId => (dispatch, getState) => {
  const data = serviceServices.deleteCategory(getState().user.authToken)(categoryId)
  dispatch({
    type: DELETE_CATEGORY.ACTION,
    payload: data,
  })
  return data
}

export const postCategory = payload => (dispatch, getState) => {
  const data = serviceServices.postCategory(getState().user.authToken)(payload)
  dispatch({
    type: POST_CATEGORY.ACTION,
    payload: data,
  })
  return data
}

export const updateCategory = (categoryId, payload) => (dispatch, getState) => {
  const data = serviceServices.updateCategory(getState().user.authToken)(categoryId, payload)
  dispatch({
    type: UPDATE_CATEGORY.ACTION,
    payload: data,
  })
  return data
}

// ** ----------------- SERVICE ACTIONS
export const getServiceList = params => (dispatch, getState) => {
  const data = serviceServices.getServiceList(getState().user.authToken)(
    humps.decamelizeKeys(params)
  )
  dispatch({
    type: GET_SERVICE_LIST.ACTION,
    payload: data,
    meta: params,
  })
  return data
}

export const getServiceById = serviceId => (dispatch, getState) => {
  const data = serviceServices.getServiceById(getState().user.authToken)(serviceId)
  dispatch({
    type: GET_SERVICE_BY_ID.ACTION,
    payload: data,
  })
  return data
}

export const updateService = (serviceId, payload) => (dispatch, getState) => {
  const data = serviceServices.updateService(getState().user.authToken)(serviceId, payload)
  dispatch({
    type: UPDATE_SERVICE.ACTION,
    payload: data,
  })
  return data
}

export const postService = payload => (dispatch, getState) => {
  const data = serviceServices.postService(getState().user.authToken)(payload)
  dispatch({
    type: POST_SERVICE.ACTION,
    payload: data,
  })
  return data
}

export const deleteService = serviceId => (dispatch, getState) => {
  const data = serviceServices.deleteService(getState().user.authToken)(serviceId)
  dispatch({
    type: DELETE_SERVICE.ACTION,
    payload: data,
  })
  return data
}
// ** --------------------------------------

// ** ----------------- PROBLEM ACTIONS
export const getProblemList = params => async (dispatch, getState) => {
  const data = await serviceServices.getProblemList(getState().user.authToken)(
    humps.decamelizeKeys(params)
  )
  dispatch({
    type: GET_PROBLEM_LIST.ACTION,
    payload: async () => data,
    meta: params,
  })
  return data
}

export const getProblemById = problemId => async (dispatch, getState) => {
  const data = await serviceServices.getProblemById(getState().user.authToken)(problemId)
  dispatch({
    type: GET_PROBLEM_BY_ID.ACTION,
    payload: async () => data,
  })
  return data
}

export const deleteProblem = problemId => async (dispatch, getState) => {
  const data = await serviceServices.deleteProblem(getState().user.authToken)(problemId)
  dispatch({
    type: DELETE_PROBLEM.ACTION,
    payload: async () => data,
  })
  return data
}

export const postProblem = payload => async (dispatch, getState) => {
  const data = await serviceServices.postProblem(getState().user.authToken)(payload)
  dispatch({
    type: POST_PROBLEM.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateProblem = (problemId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.updateProblem(getState().user.authToken)(problemId, payload)
  dispatch({
    type: UPDATE_PROBLEM.ACTION,
    payload: async () => data,
  })
  return data
}

// ** --------------------------------------

export const getActiveBudget = serviceOrderId => async (dispatch, getState) => {
  const data = await serviceServices.getActiveBudget(getState().user.authToken)(serviceOrderId)
  dispatch({
    type: GET_ACTIVE_BUDGET.ACTION,
    payload: async () => data,
  })
  return data
}

export const getServiceOrdersPdf = params => (dispatch, getState) => {
  const queryParams = {
    pageSize: params?.pageSize || getState().serviceOrders.size,
    order: params?.order || getState().serviceOrders.params?.order,
    orderStatus: params?.orderStatus || getState().serviceOrders.orderStatus,
    ...params,
  }
  const data = serviceServices.getServiceOrders(getState().user.authToken)(queryParams)
  dispatch({
    type: GET_SERVICE_ORDERS_PDF.ACTION,
    payload: data,
    meta: queryParams,
  })
  return data
}

export const getMoreServiceOrders = params => (dispatch, getState) =>
  dispatch({
    type: GET_MORE_SERVICE_ORDERS.ACTION,
    payload: serviceServices.getServiceOrders(getState().user.authToken)({
      pageSize: getState().serviceOrders.size,
      order: getState().serviceOrders.params?.order,
      page: getState().serviceOrders.next,
      orderStatus: getState().serviceOrders.orderStatus,
      ...params,
    }),
  })

export const getTradesmanById = tradesmanId => (dispatch, getState) => {
  const data = serviceServices.getTradesmanById(getState().user.authToken)(tradesmanId)
  dispatch({
    type: GET_TRADESMAN_BY_ID.ACTION,
    payload: data,
  })
  return data
}

export const getServiceOrder =
  (serviceOrderId, isCurrent = true) =>
  async (dispatch, getState) => {
    const data = await serviceServices.getServiceOrder(getState().user.authToken)(serviceOrderId)
    dispatch({
      type: GET_SERVICE_ORDER.ACTION,
      payload: async () => data,
      meta: { isCurrent },
    })
    return data
  }

export const postSendRatingLink = serviceOrderId => async (dispatch, getState) => {
  const data = await serviceServices.postSendRatingLink(getState().user.authToken)(serviceOrderId)
  dispatch({
    type: POST_SEND_RATING_LINK.ACTION,
    payload: async () => data,
  })
  return data
}

export const postSendRating = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.postSendRating(getState().user.authToken)(
    serviceOrderId,
    payload
  )

  dispatch({
    type: POST_SEND_RATING.ACTION,
    payload: async () => data,
  })

  return data
}

export const getServiceApproved =
  (serviceOrderId, isCurrent = true) =>
  (dispatch, getState) =>
    dispatch({
      type: GET_SERVICE_APPROVED.ACTION,
      payload: serviceServices.getServiceApproved(getState().user.authToken)(serviceOrderId),
      meta: { isCurrent, serviceOrderId },
    })

export const getServiceOrderApprovalAndExecutionInfos =
  (serviceOrderId, isCurrent = true) =>
  (dispatch, getState) => {
    const data = serviceServices.getServiceOrderApprovalAndExecutionInfos(
      getState().user.authToken
    )(serviceOrderId)
    dispatch({
      type: GET_SERVICE_ORDER_APPROVAL_AND_EXECUTION_INFOS.ACTION,
      payload: data,
      meta: { isCurrent, serviceOrderId },
    })
    return data
  }

export const postStatusChange = serviceOrderId => (dispatch, getState) => {
  const data = serviceServices.postStatusChange(getState().user.authToken)(serviceOrderId)
  dispatch({
    type: UPDATE_SERVICE_ORDER.ACTION,
    payload: data,
  })
  return data
}

export const updateServiceOrder = payload => async (dispatch, getState) => {
  const data = serviceServices.updateServiceOrder(getState().user.authToken)(
    getState().serviceOrders.current,
    formatPayload(payload)
  )

  dispatch({
    type: UPDATE_SERVICE_ORDER.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateServiceOrderById = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = serviceServices.updateServiceOrder(getState().user.authToken)(
    serviceOrderId,
    formatPayload(payload)
  )

  dispatch({
    type: UPDATE_SERVICE_ORDER.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateServiceOrderClassifications =
  (serviceOrderId, payload) => async (dispatch, getState) => {
    const data = serviceServices.updateServiceOrderClassifications(getState().user.authToken)(
      serviceOrderId,
      formatPayload(payload)
    )

    dispatch({
      type: UPDATE_SERVICE_ORDER.ACTION,
      payload: async () => data,
    })

    return data
  }

export const updateServiceOrderDateSchedule = (serviceOrderId, payload) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_SERVICE_ORDER_DATE_SCHEDULE.ACTION,
    payload: serviceServices.updateServiceOrderDateSchedule(
      getState().user.authToken,
      serviceOrderId,
      payload
    ),
  })
}

export const updateAppointmentDateSchedule =
  (serviceOrderId, payload) => async (dispatch, getState) => {
    const data = await serviceServices.updateAppointmentDateSchedule(
      getState().user.authToken,
      serviceOrderId,
      payload
    )
    dispatch({
      type: UPDATE_APPOINTMENT_DATE_SCHEDULE.ACTION,
      payload: async () => data,
    })
    return data
  }
export const deleteAttachment = attachmentId => (dispatch, getState) =>
  dispatch({
    type: DELETE_ATTACHMENT.ACTION,
    payload: serviceServices.deleteAttachment(getState().user.authToken)(
      getState().serviceOrders.current,
      attachmentId
    ),
    meta: { attachmentId },
  })

export const cancelServiceOrder =
  (cancelReasonId, serviceOrderCancelDetails) => (dispatch, getState) =>
    dispatch({
      type: CANCEL_SERVICE_ORDER.ACTION,
      payload: serviceServices.cancelServiceOrder(getState().user.authToken)(
        getState().serviceOrders.current,
        cancelReasonId,
        serviceOrderCancelDetails
      ),
      meta: { cancelReasonId, serviceOrderCancelDetails },
    })

export const reopenServiceOrder = serviceOrderId => (dispatch, getState) =>
  dispatch({
    type: REOPEN_SERVICE_ORDER.ACTION,
    payload: serviceServices.reopenServiceOrder(getState().user.authToken)(serviceOrderId),
  })

export const verifyNewBudget =
  (serviceOrderId, isCurrent = true) =>
  (dispatch, getState) =>
    dispatch({
      type: VERIFY_NEW_BUDGET.ACTION,
      payload: serviceServices.verifyNewBudget(getState().user.authToken)(serviceOrderId),
      meta: { isCurrent, serviceOrderId },
    })

export const handlePage = payload => dispatch =>
  dispatch({
    type: HANDLE_PAGE,
    payload,
  })

export const changeUnread = id => dispatch =>
  dispatch({
    type: CHANGE_UNREAD,
    payload: { id },
  })

export const duplicateServiceOrder = () => async (dispatch, getState) => {
  const { user } = getState()
  const makeRequest = serviceServices.duplicateServiceOrder(user.authToken)
  const data = await makeRequest(getState().serviceOrders.current)

  dispatch({
    type: DUPLICATE_SERVICE_ORDER.ACTION,
    payload: async () => data,
  })

  return data
}

export const setCurrent = id => dispatch =>
  dispatch({
    type: SET_CURRENT,
    payload: { id },
  })
export const getReasonsToReprove = () => (dispatch, getState) =>
  dispatch({
    type: GET_REASONS_REPROVE.ACTION,
    payload: serviceServices.getReasonsToReprove(getState().user.authToken),
  })

export const cancelServiceOrderApproved =
  (serviceOrderId, isCurrent = true) =>
  (dispatch, getState) =>
    dispatch({
      type: GET_CANCEL_SERVICE_APPROVED.ACTION,
      payload: serviceServices.cancelServiceOrderApproved(getState().user.authToken)(
        serviceOrderId
      ),
      meta: { isCurrent, serviceOrderId },
    })

export const cancelServiceOrderRejected =
  (serviceOrderId, isCurrent = true) =>
  async (dispatch, getState) => {
    const { user } = getState()
    const makeRequest = await serviceServices.cancelServiceOrderRejected(user.authToken)(
      serviceOrderId
    )
    const result = { ...makeRequest, id: serviceOrderId }

    dispatch({
      type: GET_CANCEL_SERVICE_REJECTED.ACTION,
      payload: async () => result,
      meta: { isCurrent, serviceOrderId },
    })

    dispatch(getHistoryLogs(serviceOrderId))
  }

export const resendEmailOnCancelServiceOrderRejected =
  (serviceOrderId, payload) => (dispatch, getState) =>
    dispatch({
      type: RESEND_EMAIL_ON_CANCEL_SERVICE_REJECTED.ACTION,
      payload: serviceServices.resendEmailOnCancelServiceOrderRejected(getState().user.authToken)(
        serviceOrderId,
        payload
      ),
      meta: { serviceOrderId, payload },
    })

export const createReasonsToReprove = payload => (dispatch, getState) => {
  const serviceOrderId = getState().providers.currentProvider
  dispatch({
    type: CREATE_REASON_TO_REPROVE.ACTION,
    payload: serviceServices.createReasonsToReprove(getState().user.authToken)(
      serviceOrderId,
      payload
    ),
    meta: { serviceOrderId },
  })
}

export const cancelServiceOrderFinish =
  (serviceOrderId, reproveReason) => async (dispatch, getState) => {
    const { user } = getState()
    const makeRequest = serviceServices.cancelServiceOrderFinish(user.authToken)
    const data = await makeRequest(serviceOrderId, reproveReason)

    dispatch({
      type: CANCEL_SERVICE_ORDER_FINISH.ACTION,
      payload: async () => data,
      meta: { serviceOrderId, reproveReason },
    })

    dispatch(getHistoryLogs(serviceOrderId))
  }

export const closeServiceOrder = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.closeServiceOrder(getState().user.authToken)(
    serviceOrderId,
    payload
  )
  dispatch({
    type: CLOSE_SERVICE_ORDER.ACTION,
    payload: async () => data,
    meta: { serviceOrderId, payload },
  })
  return data
}

export const sendExecutionProofsAttachments =
  (serviceOrderId, payload) => async (dispatch, getState) => {
    const data = await serviceServices.sendExecutionProofsAttachments(getState().user.authToken)(
      serviceOrderId,
      payload
    )
    dispatch({
      type: SEND_EXECUTION_PROOFS_ATTACHMENTS.ACTION,
      payload: async () => data,
      meta: { serviceOrderId, payload },
    })
    return data
  }

export const deleteExecutionProofsAttachments = serviceOrderId => (dispatch, getState) =>
  dispatch({
    type: DELETE_EXECUTION_PROOFS_ATTACHMENTS.ACTION,
    payload: serviceServices.deleteExecutionProofsAttachments(getState().user.authToken)(
      serviceOrderId
    ),
  })

export const sendInvoice = (serviceOrderId, payload) => (dispatch, getState) => {
  const data = serviceServices.sendInvoice(getState().user.authToken)(serviceOrderId, payload)
  dispatch({
    type: SEND_INVOICE.ACTION,
    payload: data,
    meta: { serviceOrderId, payload },
  })
  return data
}

export const deleteInvoice = serviceOrderId => async (dispatch, getState) => {
  const data = await serviceServices.deleteInvoice(getState().user.authToken)(serviceOrderId)

  dispatch({
    type: DELETE_INVOICE.ACTION,
    payload: async () => data,
  })

  return data
}

export const updateStepStatus = (serviceOrderId, payload) => (dispatch, getState) => {
  const data = serviceServices.updateStepStatus(getState().user.authToken)(serviceOrderId, payload)
  dispatch({
    type: UPDATE_STEP_STATUS.ACTION,
    payload: data,
    meta: { serviceOrderId, payload },
  })
  return data
}

export const registerExecution = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.registerExecution(getState().user.authToken)(
    serviceOrderId,
    payload
  )
  dispatch({
    type: REGISTER_EXECUTION.ACTION,
    payload: async () => data,
    meta: { serviceOrderId, payload },
  })
  return data
}

export const scheduleExecution = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.scheduleExecution(getState().user.authToken)(
    serviceOrderId,
    payload
  )
  dispatch({
    type: SCHEDULE_EXECUTION.ACTION,
    payload: async () => data,
    meta: { serviceOrderId, payload },
  })

  return data
}

export const getPaymentAnticipations = params => (dispatch, getState) => {
  const data = serviceServices.getPaymentAnticipations(getState().user.authToken)({
    pageSize: params.pageSize,
    page: params.page,
    orderBy: params.orderBy,
    ...params,
  })
  dispatch({
    type: GET_PAYMENT_ANTICIPATIONS.ACTION,
    payload: data,
  })
  return data
}

/**
 *
 * Para buscar o status do chamado no momento em que o solicitante acessa a pagina de avaliacao de servico
 *
 */
// eslint-disable-next-line no-unused-vars
export const getServiceorderStatus = serviceOrderId => (dispatch, getState) => {
  const data = serviceServices.getServiceorderStatus(
    getState().user.authToken
      ? getState().user.authToken
      : new URLSearchParams(window.location.search).get('authorization'),
    serviceOrderId
  )
  dispatch({
    type: GET_SERVICE_ORDER_STATUS.ACTION,
    payload: data,
  })
  return data
}

export const getPaymentAnticipationInfo = serviceOrderId => async (dispatch, getState) => {
  const data = await serviceServices.getPaymentAnticipationInfo(getState().user.authToken)(
    serviceOrderId
  )
  dispatch({
    type: GET_PAYMENT_ANTICIPATION_INFO.ACTION,
    payload: async () => data,
  })
  return data
}

export const createPaymentAnticipation = payload => (dispatch, getState) =>
  dispatch({
    type: CREATE_PAYMENT_ANTICIPATION.ACTION,
    payload: serviceServices.createPaymentAnticipation(getState().user.authToken)(payload),
  })

export const reactivateServiceOrder =
  (serviceOrderId, isCurrent = true) =>
  (dispatch, getState) =>
    dispatch({
      type: REACTIVATE_SERVICE_ORDER.ACTION,
      payload: serviceServices.reactivateServiceOrder(getState().user.authToken)(serviceOrderId),
      meta: { isCurrent, serviceOrderId },
    })

export const getServiceOrderTradesman =
  (serviceOrderId, isCurrent = true) =>
  (dispatch, getState) =>
    dispatch({
      type: GET_SERVICE_ORDER_TRADESMAN.ACTION,
      payload: serviceServices.getServiceOrderTradesman(getState().user.authToken)(serviceOrderId),
      meta: { isCurrent, serviceOrderId },
    })

export const getReasonsToRefuseMetabaseSuggestion = () => (dispatch, getState) =>
  dispatch({
    type: GET_REASONS_TO_REFUSE_METABASE_SUGGESTION.ACTION,
    payload: serviceServices.getReasonsToRefuseMetabaseSuggestion(getState().user.authToken),
  })

export const setDefaultServiceOrderFilter = payload => dispatch => {
  dispatch({
    type: SET_DEFAULT_SERVICE_ORDER_FILTER,
    payload,
  })
}

export const updateServiceOrderFilter = payload => dispatch => {
  dispatch({
    type: UPDATE_SERVICE_ORDER_FILTER,
    payload,
  })
}

export const getStepStatusFilter =
  (params = {}) =>
  async (dispatch, getState) => {
    const decamelizedParams = humps.decamelizeKeys(params)
    const data = await serviceServices.getStepStatusFilter(getState().user.authToken)(
      decamelizedParams
    )

    dispatch({
      type: GET_STEP_STATUS_FILTER.ACTION,
      payload: async () => data,
      meta: params,
    })
    return data
  }

export const getStepStatusOptions = params => (dispatch, getState) =>
  dispatch({
    type: GET_ALL_STEP_STATUS_OPTIONS.ACTION,
    payload: serviceServices.getStepStatusOptions(getState().user.authToken)(params),
  })

export const getAllStepStatusFilterOptions = () => (dispatch, getState) =>
  dispatch({
    type: GET_ALL_STEP_STATUS_FILTER_OPTIONS.ACTION,
    payload: serviceServices.getAllStepStatusFilterOptions(getState().user.authToken),
  })

export const getButtonsAction = params => (dispatch, getState) => {
  const decamelizedParams = humps.decamelizeKeys(params)
  const data = serviceServices.getButtonsAction(getState().user.authToken)(decamelizedParams)

  dispatch({
    type: GET_BUTTONS_ACTION.ACTION,
    payload: data,
    meta: params,
  })
  return data
}

export const getGenericParameters = params => async (dispatch, getState) => {
  const decamelizedParams = humps.decamelizeKeys(params)
  const data = await serviceServices.getGenericParameters(getState().user.authToken)(
    decamelizedParams
  )
  dispatch({
    type: GET_GENERIC_PARAMETERS.ACTION,
    payload: async () => data,
    meta: params,
  })
  return data
}

export const getGenericParametersAgencies = params => async (dispatch, getState) => {
  const decamelizedParams = humps.decamelizeKeys(params)
  const data = await serviceServices.getGenericParametersAgencies(getState().user.authToken)(
    decamelizedParams
  )
  dispatch({
    type: GET_GENERIC_PARAMETERS.ACTION,
    payload: async () => data,
    meta: params,
  })
  return data
}

export const getGenericParametersNewForm = params => async (dispatch, getState) => {
  const decamelizedParams = humps.decamelizeKeys(params)
  const data = await serviceServices.getGenericParametersNewForm(getState().user.authToken)(
    decamelizedParams
  )

  dispatch({
    type: GET_GENERIC_PARAMETERS_NEW_FORM.ACTION,
    payload: async () => data,
    meta: params,
  })

  return data
}

export const getRequesterGenericParameters = params => async (dispatch, getState) => {
  const decamelizedParams = humps.decamelizeKeys(params)
  const data = await serviceServices.getRequesterGenericParameters(getState().user.authToken)(
    decamelizedParams
  )
  dispatch({
    type: GET_REQUESTER_GENERIC_PARAMETERS.ACTION,
    payload: async () => data,
    meta: params,
  })
  return data
}

export const completeCanceledServiceOrder = serviceOrderId => (dispatch, getState) => {
  dispatch({
    type: COMPLETE_CANCELED_SERVICE_ORDER.ACTION,
    payload: serviceServices.completeCanceledServiceOrder(getState().user.authToken)(
      serviceOrderId
    ),
  })
}

export const getReactivateButton = serviceOrderId => (dispatch, getState) =>
  dispatch({
    type: GET_REACTIVATE_BUTTON.ACTION,
    payload: serviceServices.getReactivateButton(getState().user.authToken)(serviceOrderId),
  })

export const refuseServiceOrder = (serviceId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.refuseServiceOrder(getState().user.authToken)(
    serviceId,
    payload
  )
  dispatch({
    type: REFUSE_SERVICE_ORDER.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateDistanceBudget = payload => async (dispatch, getState) => {
  const data = await serviceServices.updateDistanceBudget(getState().user.authToken)(
    getState().serviceOrders.current,
    formatPayload(payload)
  )
  dispatch({
    type: UPDATE_DISTANCE_BUDGET.ACTION,
    payload: async () => data,
  })
  return data
}

export const reproveServiceOrder = payload => async dispatch => {
  const data = await serviceServices.reproveserviceOrder(null)(payload)
  dispatch({
    type: REPROVE_SERVICE_ORDER.ACTION,
    payload: async () => data,
  })
  return data
}

export const cancelExecutionSchedule = payload => async (dispatch, getState) => {
  const data = await serviceServices.cancelExecutionSchedule(getState().user.authToken)(payload)
  dispatch({
    type: POST_CANCEL_EXECUTION_SCHEDULE.ACTION,
    payload: async () => data,
  })
  return data
}

export const cancelExecution = serviceOrderId => async (dispatch, getState) => {
  const data = await serviceServices.cancelExecution(getState().user.authToken)(serviceOrderId)
  dispatch({
    type: CANCEL_EXECUTION.ACTION,
    payload: async () => data,
  })
  return data
}

export const updateServiceOrderBusinessFront =
  (serviceOrderId, payload) => async (dispatch, getState) => {
    const data = await serviceServices.updateServiceOrderBusinessFront(getState().user.authToken)(
      serviceOrderId,
      formatPayload(payload)
    )

    dispatch({
      type: UPDATE_SERVICE_ORDER.ACTION,
      payload: async () => data,
    })

    return data
  }

export const updateServiceOrderFlowsOptions = options => ({
  type: UPDATE_SERVICE_ORDER_FLOWS_OPTIONS.ACTION,
  payload: options,
})

export const getOptionsPaymentResponsible = serviceOrderId => (dispatch, getState) => {
  const data = serviceServices.getOptionsPaymentResponsible(getState().user.authToken)(
    serviceOrderId
  )
  dispatch({
    type: GET_OPTIONS_PAYMENT_RESPONSIBLE.ACTION,
    payload: data,
  })
  return data
}

export const verifyWhatsappUpdate = serviceOrderId => (dispatch, getState) => {
  const data = serviceServices.verifyWhatsappUpdate(getState().user.authToken)(serviceOrderId)
  dispatch({
    type: VERIFY_WHATSAPP_UPDATE.ACTION,
    payload: data,
  })
  return data
}

export const updateReferaService = (serviceOrderId, payload) => async (dispatch, getState) => {
  const data = await serviceServices.updateReferaService(getState().user.authToken)(
    serviceOrderId,
    payload
  )

  dispatch({
    type: UPDATE_REFERA_SERVICE.ACTION,
    payload: async () => data,
  })

  return data
}
